@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.smartdp-banner-flashsale {
	.relative-link__text {
		width: 100%;

		@include gridle_state(md) {
			@include container;
			position: relative;
		}
	}

	img {
		cursor: pointer;
	}

	&--immersive {
		position: relative;
		padding: 0;
		height: 100%;

		.relative-link__text {
			display: block;
			width: 100%;
			height: 100%;
			max-width: initial;

			@include gridle_state(md) {
				width: 100%;
				height: 400px;
			}
		}

		@include gridle_state(md) {
			height: 400px;
		}
	}

	&__foreground {
		position: absolute;
		width: 100%;
		top: 0;
		height: 100%;
		padding: 30px 40px;
		box-sizing: border-box;
	}

	&__text {
		max-width: 1080px;
		width: 100%;
		height: 100%;
		margin: 0 auto;
		display: flex;
		align-items: center;

		img {
			width: 100%;
		}
	}
}
