@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.click-to-call {
	display: flex;
	flex-direction: column;
	max-width: unset;

	@include gridle_state(md) {
		max-width: 330px;
	}

	&__unavailable-service {
		margin-bottom: 25px;
	}

	&__instructions {
		margin-bottom: 15px;
	}

	&__form--unavailable {
		opacity: 0.2;
		pointer-events: none;
	}
}
