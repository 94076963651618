@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$badge-item-border-radius: none !default;
$black: black !default;

.badge-list {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	align-items: center;

	&__item {
		pointer-events: none;
		display: flex;

		&--activable {
			pointer-events: auto;

			@include gridle_state(md) {
				&:hover {
					cursor: pointer;
					transform: scale(1.1);
				}
			}
		}

		&--second {
			.colored-product-badge {
				color: $black;
				border: 1px solid $black;
				background: white;
			}
		}
	}
}
