@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$badge-background-color: $active-color !default;
$badge-border: none !default;
$badge-border-radius: $border-radius-small !default;

.badge {
	$this: &;
	font-size: 1.2rem;
	letter-spacing: 0;
	border-radius: $badge-border-radius;
	background: var(--color-background-badge-inclus, $badge-background-color);
	color: var(--color-text-badge-inclus, $badge-font-color);
	padding: 4px 10px;
	border: $badge-border;
	display: inline-flex;
	align-items: center;

	&__item {
		display: flex;
		align-items: center;

		+ #{$this}__item {
			margin-left: 5px;

			&:before {
				content: "|";
				margin-right: 5px;
			}
		}
	}
}
