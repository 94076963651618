@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$colored-product-badge-background-color: $color-primary !default;
$colored-product-badge-text-transform: none !default;
$colored-product-badge-border-radius: $border-radius-small !default;

.colored-product-badge {
	height: 20px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 4px 8px 3px;
	box-sizing: border-box;
	color: white;
	background-color: $colored-product-badge-background-color;
	border-radius: $colored-product-badge-border-radius;
	text-transform: uppercase;
	font-size: 1.15rem;

	&__img {
		display: block;
		width: 10px;
		height: 10px;
		margin-right: 4px;
	}

	&--picto {
		@extend %text-bold;

		padding: 3px 12px 3px 8px;
	}
}
