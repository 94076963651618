@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.carousel-dots {
	@include gridle_state(md) {
		transition: opacity 0.5s ease;
	}

	&__item {
		width: 6px;
		height: 6px;

		@include gridle_state(md) {
			width: 12px;
			height: 12px;
		}
	}

	&__dot {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: rgba(239, 240, 244, 0.7);
		opacity: 1;
		flex-shrink: 0;
		transition: transform 0.5s ease, background 0.3s ease-in-out, opacity 0.3s ease-in-out;

		&--active {
			background-color: white;
		}

		&--visible {
			opacity: 1;
		}

		&--small {
			transform: scale(0.6667);
		}

		&--medium {
			transform: scale(0.8333);
		}

		&--big {
			transform: scale(1);
		}
	}
}
