@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.payment-description {
	&__content {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: var(--payment-description-background-color, #daf3ff);
		border-radius: $border-radius-big;
		flex-direction: column;
		word-wrap: break-word;
		padding: 8px 15px;
		line-height: 1.6;

		@include gridle_state(md) {
			flex-direction: unset;
			padding: 12px 24px;
			line-height: 1.4;
		}

		.payment-description__terms {
			text-align: center;

			@include gridle_state(md) {
				display: flex;
				align-items: center;
				text-align: unset;
			}

			.custom-formatted-message {
				&__rendered-message {
					@include gridle_state(md) {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
					}
				}

				&__value {
					color: white;
					background: var(
									--payment-description-instalments-background-color,
									#36b0ec
					);
					margin: 0 4px;
					display: inline-block;
					border-radius: 2px;
					padding: 1px 8px;
					line-height: 1.4;

					@include gridle_state(md) {
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 0 8px;
						border-radius: 4px;
						padding: 1px 8px;
						height: 30px;
						box-sizing: border-box;

						&:first-child {
							margin-left: 8px;
						}
					}
				}
			}

			svg {
				display: flex;
			}

			.floating-button {
				margin-left: 4px;
				display: inline-block;
				vertical-align: middle;
				@include gridle_state(md) {
					display: unset;
					vertical-align: unset;
				}
			}
		}
	}
}